export const staffsByGroupStatus = /* GraphQL */ `
  query StaffsByGroupStatus(
    $group: String
    $status: ModelStringKeyConditionInput	
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroupStatus(
      group: $group
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
      }
      nextToken
    }
  }
`;

export const staffsByGroup = /* GraphQL */ `
  query StaffsByGroup(
    $group: String
    $firstName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    staffsByGroup(
      group: $group
      firstName: $firstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
      }
      nextToken
    }
  }
`;

export const getStaffNames = /* GraphQL */ `
  query GetStaff($id: ID!) {
    getStaff(id: $id) {
      firstName
      lastName
    }
  }
`;

export const getCounselingType = /* GraphQL */ `
  query GetCounselingType {
    __type(name: "CounselingType") {
      enumValues {
        name
      }
    }
  }
`;
export const getCounseling = /* GraphQL */ `
  query GetCounseling($id: ID!) {
    getCounseling(id: $id) {
      id
      group
      date
      refusedToSign
      signatureAcknowledge
      signature
      internalStaffNotes
      counselingNotes
      employeeNotes
      correctiveActionSummary
      priorDiscussionSummary
      consequencesOfFailure
      dateSent
      status
      dateSigned
      tenantId
      createdFrom
      createdAt
      updatedAt
      severity {
        id
        option
      }
      images {
        items {
          id
          group
          name
          key
          type
          uploadDate
          notes
          documentDate
          isDocVisibleToAssociate
          createdAt
          updatedAt
        }
        nextToken
      }
      staff {
        id
        group
        transporterId
        firstName
        lastName
        alternateNames
        phone
        email
        keyFocusArea
        keyFocusAreaCompleted
        coachingOpportunity
        status
        dlExpiration
        vehicleReport
        gasCardPin
        dob
        gender
        pronouns
        assignedLead
        isALead
        hourlyStatus
        hireDate
        terminationDate
        finalCheckIssueDate
        returnedUniform
        latestScorecard
        smsLastMessageTimestamp
        smsLastMessage
        vehicleType
        photo
        onboardingPinned
        netradyneDriverId
        heraScore
        heraRank
        prevHeraRank
        receiveTextMessages
        receiveEmailMessages
        notes
        onboardingNotes
        customDeliveryVan
        authorizedLBS
        authorizedToDrive {
          nextToken
        }
        preferredDaysOff
        createdAt
        updatedAt
      }
      user {
        id
        group
        cognitoSub
        firstName
        lastName
        phone
        email
        emailVerified
        role
        isOwner
        isCreator
        receiveSmsTaskReminders
        receiveEmailTaskReminders
        receiveSmsTaskAssignments
        receiveEmailTaskAssignments
        permissionLogin
        permissionFullAccess
        permissionDocuments
        permissionCounselings
        permissionAccidents
        permissionInjuries
        permissionDrugTests
        permissionDailyRostering
        permissionMessenger
        permissionPerformanceCoaching
        permissionDAManagement
        permissionCustomLists
        permissionManageLabels
        permissionVehicleManagement
        permissionTasksReports
        permissionMessageTemplate
      }
    }
  }
`;

export const usersByTenant = /* GraphQL */ `
  query UsersByTenant(
    $userTenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenant(
      userTenantId: $userTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        firstName
        lastName
        phone
        email
      }
      nextToken
    }
  }
`;

